<template>
  <div>
    <d-text-field
      v-if="item?.update"
      v-model="item.name"
      size="sm"
      class="label-text"
    />
    <span v-else>
      {{ item.name }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: this
    }
  },
}
</script>
<style lang="scss" scoped>
/deep/ .label-text {
  input {
    border: 1px solid #9b9b9b;
  }
}
</style>
